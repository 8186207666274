import React from "react";

const UnitRow = (props) => {
  return (
    <tr>
      <td><img src={props.image} alt={props.obj.name}/> {props.obj.name}</td>
      <td>
        <input
          onChange={(e) => props.change(props.obj.type, e.target.value)}
          value={props.obj.amount}
          type="text"
        />
      </td>
      <td>{props.obj.r1}</td>
      <td>{props.obj.r2}</td>
      <td>{props.obj.r3}</td>
      <td>{props.obj.r4}</td>
    </tr>
  );
};

export default UnitRow;
