import { useState } from "react";
import Scavenging from "./Scavenging/Scavenging";
import UnitRow from "./UnitRow/UnitRow";

import imageSpear from "./img/unit_spear.png";
import imageSword from "./img/unit_sword.png";
import imageAxe from "./img/unit_axe.png";
import imageLight from "./img/unit_light.png";
import imageHeavy from "./img/unit_heavy.png";
import imagePaladin from "./img/unit_knight.png";
import ressources from "./img/ressources.png";

function App() {
  const [units, setUnits] = useState([
    { type: "spear", haul: [0.8333, 2.083, 4.167, 6.25], amount: 0, name: "Speerkämpfer", r1: 0, r2: 0, r3: 0, r4: 0 },
    { type: "sword", haul: [0.5, 1.25, 2.5, 3.75], amount: 0, name: "Schwertkämpfer", r1: 0, r2: 0, r3: 0, r4: 0 },
    { type: "axe", haul: [0.333, 0.833, 1.667, 2.5], amount: 0, name: "Axtkämpfer", r1: 0, r2: 0, r3: 0, r4: 0 },
    { type: "light", haul: [2.667, 6.667, 13.333, 20.0], amount: 0, name: "Liechti Kavallerie", r1: 0, r2: 0, r3: 0, r4: 0 },
    { type: "heavy", haul: [1.667, 4.167, 8.333, 12.5], amount: 0, name: "Schwäri Kavallerie", r1: 0, r2: 0, r3: 0, r4: 0 },
    { type: "paladin", haul: [3.333, 8.333, 16.667, 25.0], amount: 0, name: "Paladin", r1: 0, r2: 0, r3: 0, r4: 0 },
  ]);

  const [unlocked, setUnlocked] = useState(2);
  const [expectedRessources, setExpectedRessources] = useState(0);

  const changeUnit = (type, amount) => {
    amount = parseInt(amount);

    if (isNaN(amount)) {
      amount = 0;
    }

    let tmp = [...units];
    let foundUnitIndex = tmp.findIndex((el) => el.type === type);
    tmp[foundUnitIndex].amount = amount;
    setUnits(tmp);
  };

  const changeUnlocked = (val) => {
    setUnlocked(parseInt(val));
  };

  const reset = () => {
    let tmp = [...units];

    tmp.forEach((unit) => {
      unit.amount = 0;
      unit.r1 = 0;
      unit.r2 = 0;
      unit.r3 = 0;
      unit.r4 = 0;
    });
    setUnits(tmp);
    calculateUnitAmount();
  };

  const calculateUnitAmount = () => {
    let tmp = [...units];
    let expRess = 0;

    tmp.forEach((unit) => {
      if (unlocked === 2) {
        let r1Amount = Math.round(2 * (unit.amount / 3));
        let r2Amount = Math.round(unit.amount / 3);

        unit.r1 = r1Amount;
        unit.r2 = r2Amount;
        unit.r3 = 0;
        unit.r4 = 0;

        expRess += r1Amount * unit.haul[0];
        expRess += r2Amount * unit.haul[1];
      } else if (unlocked === 3) {
        let r1Amount = Math.round(4 * (unit.amount / 7));
        let r2Amount = Math.round(2 * (unit.amount / 7));
        let r3Amount = Math.round(unit.amount / 7);

        unit.r1 = r1Amount;
        unit.r2 = r2Amount;
        unit.r3 = r3Amount;
        unit.r4 = 0;

        expRess += r1Amount * unit.haul[0];
        expRess += r2Amount * unit.haul[1];
        expRess += r3Amount * unit.haul[2];
      } else if (unlocked === 4) {
        let r1Amount = Math.round(8 * (unit.amount / 15));
        let r2Amount = Math.round(4 * (unit.amount / 15));
        let r3Amount = Math.round(2 * (unit.amount / 15));
        let r4Amount = Math.round(unit.amount / 15);

        unit.r1 = r1Amount;
        unit.r2 = r2Amount;
        unit.r3 = r3Amount;
        unit.r4 = r4Amount;

        expRess += r1Amount * unit.haul[0];
        expRess += r2Amount * unit.haul[1];
        expRess += r3Amount * unit.haul[2];
        expRess += r4Amount * unit.haul[3];
      }
    });

    setExpectedRessources(expRess);
    setUnits(tmp);
  };

  return (
    <div className="App">
      <h1>Roubzugsrächner</h1>
      <div>
        <h2>Freigschaltene Roubzug</h2>
        <Scavenging change={changeUnlocked} />

        <h2>Truppe</h2>
        <table>
          <thead>
            <tr>
              <td>Truppegattig</td>
              <td>Mängi</td>
              <td>#1</td>
              <td>#2</td>
              <td>#3</td>
              <td>#4</td>
            </tr>
          </thead>
          <tbody>
            <UnitRow image={imageSpear} change={changeUnit} obj={units[0]} />
            <UnitRow image={imageSword} change={changeUnit} obj={units[1]} />
            <UnitRow image={imageAxe} change={changeUnit} obj={units[2]} />
            <UnitRow image={imageLight} change={changeUnit} obj={units[3]} />
            <UnitRow image={imageHeavy} change={changeUnit} obj={units[4]} />
            <UnitRow image={imagePaladin} change={changeUnit} obj={units[5]} />
          </tbody>
        </table>

        <section>
          <p>
            Erwarteti Ressource:{" "}
            <span style={{float: "right", fontWeight: "bold"}}>
              <img src={ressources} alt="Ressource" /> {Math.round(expectedRessources).toLocaleString().replace(/,/g, "'")}
            </span>
          </p>
        </section>

        <button className="reset" onClick={reset}>
          Zrucksetze
        </button>
        <button className="button" onClick={calculateUnitAmount}>
          Berächne
        </button>
      </div>
    </div>
  );
}

export default App;
